import request from 'utils/request';
const api = {
  DEVICE_ACTIVE_PAGE: '/api/admin/mc/report/device/active/page',
};

// 分页
export function fetchDeviceActivePage(query) {
  return request({
    url: api.DEVICE_ACTIVE_PAGE,
    method: 'get',
    params: query,
  });
}
