<template>
  <div class="ddj-clickCount">
    <!-- <div class="main-Title bgff"><h2>Device Active</h2></div> -->
    <div class="mainBox">
      <!-- <el-card shadow="hover">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <el-col :span="4">
              <el-form-item label="offer ID:"
                ><el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.id"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="OS:" label-width="50px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="上游名称:">
                <el-select
                  v-model="filter.advertiserName"
                  placeholder="Please select Advertiser"
                  class="mr10"
                >
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.advertiser"
                    :key="item.id"
                    :value="item.advertiserName"
                    :label="item.advertiserName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2"
              ><el-button type="primary" size="mini" @click="getList('filter')"
                >Search</el-button
              ></el-col
            >
          </el-row>
        </el-form>
      </el-card> -->
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="Create Time" prop="createTime"></el-table-column>
          <el-table-column label="Download Url" prop="url"></el-table-column>
          <el-table-column label="action" width="210" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini"
                ><a :href="scope.row.url">Download</a></el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import deviceActiveCon from '../../controllers/DDJ/v3/deviceActive';
  export default {
    name: 'DeviceActive',
    ...deviceActiveCon,
  };
</script>

<style></style>
